import {buildCustomSlice, createGenericAsyncThunk} from '../../generic';
import {FacilityEntity, PageableList, UniqueIdentifier} from '../../../types';
import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../../index';
import {Student} from '../student';
import {API_URL} from '../../../config';

export type Message = {
  id: number;
  createdOn: string;
  sender: string;
  subjectPL: string;
  messagePL: string;
  subjectEN: string;
  messageEN: string;
  files: Image[];
  recipientType: 'PERSONAL' | 'GROUP' | 'ALL';
  recipients: string;
  students: Student[];
};

type Image = {
  path: string
  contentType: string
  originalName?: string
}

export type EventDelivery = {
    student: string
    parents: string[]
}

export type Gallery = {
  id: number;
  createdOn: string;
  sender: string;
  subjectPL: string;
  subjectEN: string;
  messageEN: string;
  messagePL: string;
  photos: Image[];
  recipientType: 'PERSONAL' | 'GROUP' | 'ALL';
  recipients: string;
  students: Student[];
};

export const fetchFacilityMessages = createGenericAsyncThunk<PageableList<Message>, UniqueIdentifier>(
  'events/fetchFacilityMessages',
  async (facilityId, { extra, rejectWithValue }) => {
    // @ts-ignore
      const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/events/announcements?page=0&size=15`);
    const data: PageableList<Message> = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchMoreFacilityMessages = createGenericAsyncThunk<PageableList<Message>, { facilityId: number,  page: number, size: number }>(
    'events/fetchMoreFacilityMessages',
    async ({facilityId, page, size}, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/events/announcements?page=${page}&size=${size}`);
        const data: PageableList<Message> = await response.json();

        if (response.status !== 200) {
            return rejectWithValue(extra.processRejectedValue(response, data));
        }
        return data;
    }
);

export const fetchEventDeliveries = createGenericAsyncThunk<EventDelivery[], { facilityId: number, id: number }>(
    'events/fetchEventDeliveries',
    async ({facilityId, id}, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/events/${id}/delivery`);
        const data: EventDelivery[] = await response.json();

        if (response.status !== 200) {
            return rejectWithValue(extra.processRejectedValue(response, data));
        }
        return data;
    }
);

export const fetchFacilityGalleries = createGenericAsyncThunk<PageableList<Gallery>, UniqueIdentifier>(
  'events/fetchFacilityGalleries',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/events/galleries?page=0&size=15`);
    const data: PageableList<Gallery> = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchMoreFacilityGalleries = createGenericAsyncThunk<PageableList<Gallery>, { facilityId: number,  page: number, size: number }>(
    'events/fetchMoreFacilityGalleries',
    async ({facilityId, page, size}, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/events/galleries?page=${page}&size=${size}`);
        const data: PageableList<Gallery> = await response.json();

        if (response.status !== 200) {
            return rejectWithValue(extra.processRejectedValue(response, data));
        }
        return data;
    }
);

export const createMessage = createGenericAsyncThunk<Message, FacilityEntity<Message>>(
  'messages/createFacilityMessage',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/events/announcements`, value);

    const data: Message = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const createGallery = createGenericAsyncThunk<Gallery, FacilityEntity<Gallery>>(
  'messages/createFacilityMessage',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/events/galleries`, value);

    const data: Gallery = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const eventMessagesSlice = buildCustomSlice<PageableList<Message>>(
    'user/MessageFetchSlice',
    (builder) => {

        builder.addCase(fetchFacilityMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreFacilityMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...state.data.content,...action.payload.content]} : action.payload;
            delete state.error;
        });
    }
);

export const eventMessagesReducer = eventMessagesSlice.reducer;
export const selectEventMessages = createSelector([(state: RootState) => state.eventMessages], (c) => c.data);

export const eventGalleriesSlice = buildCustomSlice<PageableList<Gallery>>(
    'user/GalleryFetchSlice',
    (builder) => {

        builder.addCase(fetchFacilityGalleries.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreFacilityGalleries.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...state.data.content,...action.payload.content]} : action.payload;
            delete state.error;
        });
    }
);

export const eventGalleriesReducer = eventGalleriesSlice.reducer;
export const selectGalleryMessages = createSelector([(state: RootState) => state.eventGalleries], (c) => c.data);
