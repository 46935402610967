import { buildArrSlice, buildCustomSlice, createGenericAsyncThunk } from '../../generic';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import dayjs from 'dayjs';
import { ChatItem, ChatItemDate, ChatItemMessage, PageableList } from '../../../types';
import { API_URL } from '../../../config';
import {UploadedFile} from "../../../utils/files";


export type ChatMessageBody = {
  message: string;
};

export type ChatMessage = {
  message: string;
  createdOn: string;
  files: UploadedFile[];
  chatMessageType: 'TEXT' | 'IMAGE' | 'FILE'
  userFullName: string;
  userId: string;
  parentDeliveries: string[]
}

export type Chat = {
  chatId: number;
  studentFirstName: string;
  studentFamilyName: string;
  facilityGroupName: string;
  message: string;
  lastMessageTime: string;
  read: boolean
}

const convertToChatItem = (data: PageableList<ChatMessage>, userId: string): PageableList<ChatItem> => {
  const result: PageableList<ChatItem> = {...data, content: [] as ChatItem[]}

  let startDay = '';
  const resultContent: ChatItem[] = [];
  for(let i = data.content.length - 1; i >= 0; i--) {
    let message = data.content[i];
    const messageDate = dayjs(message.createdOn);
    const messageDay = messageDate.format('MMM D');
    const [month, day] = messageDay.split(' ');
    if (messageDay !== startDay) {
      resultContent.push({type: 'date', day, month } as ChatItemDate);
      startDay = messageDay;
    }
    resultContent.push({
        type: message.chatMessageType === 'TEXT' ? 'message' : message.chatMessageType.toLowerCase(),
        files: message.files,
        message: message.message,
        time: messageDate.format('HH:mm'),
        author: message.userFullName,
        userMessage: message.userId === userId,
        deliveries: message.parentDeliveries} as ChatItemMessage
    );
  }
  result.content = resultContent;

  return result;
};

export const fetchFacilityChats = createGenericAsyncThunk<Chat[], void>(
  'chats/fetch',
  async (_a, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/chats`);
    const data: Chat[] = await response.json();
    data.forEach((chat) => {
      if(chat.lastMessageTime) {
        const dayjsDate = dayjs(chat.lastMessageTime);
        chat.lastMessageTime = dayjsDate.isToday() ? dayjsDate.format('HH:mm') : dayjsDate.format('DD MMM');
      } else {
        chat.lastMessageTime = '';
      }

    })
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const fetchFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, { chatId: number, userId: string }>(
  'chats/fetchMessages',
  async ({ chatId,  userId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/chats/${chatId}/messages?page=0&size=15`);
    const data: PageableList<ChatMessage> = await response.json();

    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
    return convertToChatItem(data, userId);
  }
);

export const fetchMoreFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, {  chatId: number, userId: string, page: number, size: number}>(
  'chats/fetchMoreFacilityChatMessages',
  async ({ chatId, userId, page, size }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/chats/${chatId}/messages?page=${page}&size=${size}`);
    const data: PageableList<ChatMessage> = await response.json();
    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
    return convertToChatItem(data, userId);
  }
);

export const sendChatMessage = createGenericAsyncThunk<void, { message: string, chatId: number}>(
  'chats/sendMessage',
  async ({  message, chatId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/chats/${chatId}/messages`, { message });
    const data = await response.json();
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
  }
);

export const sendChatFile = createGenericAsyncThunk<void, { file: UploadedFile, type: 'FILE' | 'IMAGE', caption: string, chatId: number}>(
    'chats/sendFile',
    async ({  file,type,  chatId, caption }, { extra, rejectWithValue }) => {
        const response = await extra.ajax['post'](`${API_URL}/api/v1/chats/${chatId}/files`, { file, type, caption });
        const data = await response.json();
        if (response.status !== 200) {
            return rejectWithValue(extra.processRejectedValue(response, data));
        }
    }
);

export const chatSlice = buildArrSlice<Chat[], void>(
'chats',
      fetchFacilityChats.fulfilled
);

export const selectChats = createSelector([(state: RootState) => state.chats], (chats) => chats.data);
export const chatsReducer = chatSlice.reducer;

export const userChatMessagesSlice = buildCustomSlice<PageableList<ChatItem>>(
  'user/ChatFetchSlice',
  (builder) => {

    builder.addCase(fetchFacilityChatMessages.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      //@ts-ignore
      state.data = action.payload;
      delete state.error;
    });

    builder.addCase(fetchMoreFacilityChatMessages.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      //@ts-ignore
      state.data = state.data ? {...action.payload, content: [...action.payload.content, ...state.data.content]} : action.payload;
      delete state.error;
    });
  }
);

export const userChatMessagesReducer = userChatMessagesSlice.reducer;
export const selectUserChatMessages = createSelector([(state: RootState) => state.chatMessages], (c) => c.data);



